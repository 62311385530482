import { formatDistance, formatDistanceStrict, intervalToDuration, isValid, Locale, parseISO } from 'date-fns'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'

import { SessionTimeInfo } from '@/types'

type Options = Parameters<typeof format>[2]

const LOCALES = ['es', 'en']
let locale = 'es'
let timeFormat: '24h' | '12h' = '24h'

const isBrowserLocaleClockType24h = (languages: readonly string[]) => {
  if (!languages) {
    languages = []
  }

  if (Intl.Locale) {
    const lohr = new Intl.Locale(languages[0] || locale)

    if ('hourCycles' in lohr && Array.isArray(lohr.hourCycles)) {
      if (lohr.hourCycles.includes('h23') || lohr.hourCycles.includes('h24')) {
        return true
      }
    }

    // @ts-expect-error Property 'getHourCycles' does not exist on type 'Locale'
    if (typeof lohr.getHourCycles === 'function') {
      // @ts-expect-error Property 'getHourCycles' does not exist on type 'Locale'
      const hourCycles = lohr.getHourCycles()
      if (hourCycles.includes('h23') || hourCycles.includes('h24')) {
        return true
      }
    }
  }

  const hr = new Intl.DateTimeFormat(languages, { hour: 'numeric' }).format()

  return Number.isInteger(Number(hr))
}

export const setLocale = (lo: string) => {
  if (LOCALES.includes(lo)) {
    locale = lo
  }
}

export function detectMeridiem(): '24h' | '12h' {
  return isBrowserLocaleClockType24h(globalThis?.navigator?.languages || [locale]) ? '24h' : '12h'
}

export const getLocale = (): Locale => {
  return locale === 'en' ? en : es
}

export const setMeridiem = (m: '24h' | '12h') => {
  timeFormat = m
}

// export function getDeviceLocale() {
//   let defaultLocal = Localization.getLocales()[0].languageCode

//   if (!LOCALES.includes(defaultLocal)) {
//     defaultLocal = 'es'
//   }

//   return defaultLocal
// }

export const formatDate = (date: Date, dateFormat?: string, options?: Options) => {
  const locale = getLocale()
  const dateOptions = {
    ...options,
    locale,
  }
  return format(date, dateFormat ?? 'PP', dateOptions)
}

export const getReadableDate = (utcDate: string | undefined): string => {
  if (!utcDate) {
    return 'Invalid Date'
  }

  const parsedDate = parseISO(utcDate)
  const isValidDate = isValid(parsedDate)
  const locale = getLocale()
  if (isValidDate) {
    // parsedDate is a `Date` object, so you can use it directly,
    // instead of `new Date(utcDate)`
    // const messageDate = formatDate(parsedDate, 'PPP')
    // return messageDate

    // return formatDistanceToNow(parsedDate, { includeSeconds: true, unit: 'm' })
    return formatDistanceStrict(new Date(), parsedDate, { locale })
  } else {
    return 'InvalidDate'
  }

  // const options = { year: 'numeric', month: 'long', day: 'numeric' }
  // // @ts-ignore
  // return new Date(utcDate).toLocaleDateString(undefined, options)
}

export const getDatetimeAgo = (utcDate: string | undefined): string => {
  if (!utcDate) {
    return 'Invalid Date'
  }

  const parsedDate = parseISO(utcDate)
  const isValidDate = isValid(parsedDate)
  if (isValidDate) {
    // parsedDate is a `Date` object, so you can use it directly,
    // instead of `new Date(utcDate)`
    // const messageDate = formatDate(parsedDate, 'PPP')
    // return messageDate

    // return formatDistanceToNow(parsedDate, { includeSeconds: true, unit: 'm' })
    return formatDistance(parsedDate, new Date(), { addSuffix: true, locale: getLocale() })
  } else {
    return 'InvalidDate'
  }
}

const MILLISECONDS_IN_MINUTE = 1000 * 60
const MINUTES_IN_DAY = 60 * 24
const MINUTES_IN_MONTH = MINUTES_IN_DAY * 30
const MINUTES_IN_YEAR = MINUTES_IN_DAY * 365

function getTimezoneOffsetInMilliseconds(date: Date | number | string): number {
  const _date = toDate(date)
  const utcDate = new Date(
    Date.UTC(
      _date.getFullYear(),
      _date.getMonth(),
      _date.getDate(),
      _date.getHours(),
      _date.getMinutes(),
      _date.getSeconds(),
      _date.getMilliseconds(),
    ),
  )
  utcDate.setUTCFullYear(_date.getFullYear())
  return +date - +utcDate
}

const minutesInDay = 1440
const minutesInMonth = 43200
const minutesInYear = 525600

export const getDatetimeAgoShort = (utcDate: string | undefined): string => {
  if (!utcDate) {
    return 'Invalid Date'
  }

  const now = new Date()

  const parsedDate = parseISO(utcDate)
  const isValidDate = isValid(parsedDate)
  if (isValidDate) {
    const timezoneOffset = getTimezoneOffsetInMilliseconds(now) - getTimezoneOffsetInMilliseconds(parsedDate)

    const milliseconds = now.getTime() - parsedDate.getTime()
    const minutes = milliseconds / MILLISECONDS_IN_MINUTE
    const dstNormalizedMinutes = (milliseconds - timezoneOffset) / MILLISECONDS_IN_MINUTE
    let unit = ''

    if (minutes < 1) {
      unit = 's'
      return Math.round(milliseconds / 1000) + '' + unit
    } else if (minutes < 60) {
      unit = 'm'
      return Math.round(minutes) + '' + unit
    } else if (minutes < MINUTES_IN_DAY) {
      unit = 'h'
      return Math.round(minutes / 60) + '' + unit
    } else if (dstNormalizedMinutes < MINUTES_IN_MONTH) {
      unit = 'd'
      const days = Math.round(dstNormalizedMinutes / minutesInDay)
      return days + '' + unit
    } else if (dstNormalizedMinutes < MINUTES_IN_YEAR) {
      unit = 'm'
      const months = Math.round(dstNormalizedMinutes / minutesInMonth)

      return months + '' + unit
    } else {
      unit = 'y'
      const years = Math.round(dstNormalizedMinutes / minutesInYear)
      return years + '' + unit
    }
  } else {
    return 'InvalidDate'
  }
}

const outofDateLinks = Object.fromEntries([
  ['Asia/Kolkata', 'Asia/Calcutta'],
  ['Europe/Kyiv', 'Europe/Kiev'],
  ['Asia/Ho_Chi_Minh', 'Asia/Saigon'],
  ['Asia/Yangon', 'Asia/Rangoon'],
  ['Asia/Ulan_Bator', 'Asia/Ulaanbaatar'],
  ['Asia/Kathmandu', 'Asia/Katmandu'],
  ['Africa/Asmara', 'Africa/Asmera'],
  ['America/Atikokan', 'America/Coral_Harbour'],
  ['Atlantic/Faroe', 'Atlantic/Faeroe'],
  ['America/Nuuk', 'America/Godthab'],
  ['Pacific/Chuuk', 'Pacific/Truk'],
  ['Pacific/Yap', 'Pacific/Truk'],
  ['Pacific/Kanton', 'Pacific/Enderbury'],
  ['Pacific/Pohnpei', 'Pacific/Ponape'],
])

function getCannoficalTz(tz: string) {
  if (outofDateLinks[tz]) {
    return outofDateLinks[tz]
  }

  return tz
}

export function getActivitySessionTimeInfo(
  start: string,
  end: string,
  tz: string,
  isOnline = false,
  tzShort: string,
): SessionTimeInfo | null {
  let startDate = parseISO(start)
  let endDate = parseISO(end)
  const isValidDate = isValid(startDate) && isValid(endDate)

  if (isValidDate) {
    const currentTimezone = Intl.DateTimeFormat(locale).resolvedOptions().timeZone

    const currentLocale = locale
    const dateStrFormat = currentLocale === 'en' ? `eeee, MMM. d.` : `eeee d 'de' MMM.`
    const timeSeperator = currentLocale === 'en' ? ' to ' : ' a '
    const cannoficalTz = getCannoficalTz(tz)
    if (isOnline === false) {
      if (startDate.getTimezoneOffset() !== 0) {
        startDate = utcToZonedTime(startDate, cannoficalTz)
        endDate = utcToZonedTime(endDate, cannoficalTz)
      }
    }

    if (!(isValid(startDate) && isValid(endDate))) {
      return null
    }

    const timezone = tzShort ? tzShort : '' //formatInTimeZone(parseISO(start), tz, 'z')

    let isTimezoneDifferent = true

    if (isOnline === true) {
      isTimezoneDifferent = false
    }

    if (cannoficalTz === currentTimezone) {
      isTimezoneDifferent = false
    }

    let duration: Duration = { hours: 0, minutes: 0 }
    try {
      duration = intervalToDuration({
        start: startDate,
        end: endDate,
      })
    } catch (e) {
      console.log(`intervalToDuration failed `, { startDate, endDate })
    }

    return {
      dateStr: formatDate(startDate, dateStrFormat),
      localizedDateStr: formatDate(startDate, 'P'),
      start: {
        month: formatDate(startDate, 'MMM'),
        week: formatDate(startDate, 'eeee'),
        day: formatDate(startDate, 'd'),
        hour: timeFormat === '12h' ? formatDate(startDate, 'hh:mm') : formatDate(startDate, 'HH:mm') + 'h',
        meridiem: timeFormat === '12h' ? formatDate(startDate, 'aa') : '',
      },
      end: {
        month: formatDate(endDate, 'MMM'),
        week: formatDate(endDate, 'eeee'),
        day: formatDate(endDate, 'd'),
        hour: timeFormat === '12h' ? formatDate(endDate, 'hh:mm') : formatDate(endDate, 'HH:mm') + 'h',
        meridiem: timeFormat === '12h' ? formatDate(endDate, 'aa') : '',
      },
      timeSeperator,
      duration,
      timezone,
      showTimezone: isTimezoneDifferent,
    }
  }

  return null
}
